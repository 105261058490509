import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { transactionsService } from '../services';

export const FILTER_TYPES = [
  {
    id: 1,
    label: 'Vita Wallet',
    value: 'FILTER/VT_WALLET',
  },
];

export const FILTER_DATES = [
  {
    id: 1,
    label: 'Últimos 7 días',
    value: {
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
    },
  },
  {
    id: 2,
    label: 'Últimos 15 días',
    value: {
      startDate: moment().subtract(15, 'days'),
      endDate: moment(),
    },
  },
  {
    id: 3,
    label: 'Últimos 30 días',
    value: {
      startDate: moment().subtract(30, 'days'),
      endDate: moment(),
    },
  },
  {
    id: 0,
    label: 'Fecha personalizada',
    value: {
      startDate: null,
      endDate: null
    },
  },
];

const useFilterTransactions = ({ user, route }) => {
  const [date, setDate] = useState(FILTER_DATES[1]);
  const [type, setType] = useState(FILTER_TYPES[0]);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  const fetchTransactions = useCallback(async (dateRange) => {
    try {
      setLoading(true);
      let response = { data: [] };

      if (FILTER_TYPES[0].value === type.value) {
        response = await transactionsService.getFilterTransactions(user.headers, {
          startDate: dateRange.value.startDate.format(),
          endDate: dateRange.value.endDate.format(),
          currency: route.params?.currency,
        });
      }

      setTransactions(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
  }, [user, type, route]);

  const sendEmail = useCallback(async (isCsv) => {
    setLoading(true);
    const payload = {
      ...date.value,
      isCsv,
      type: type.value,
      currency: route.params?.currency,
    }
    try {
      const response = await transactionsService.sendHistoryEmail(user.headers, payload);
      setLoading(false);
      return response;
    } catch (e) {
      setLoading(false);
      return e;
    }
  }, [user, date, type, route]);

  useEffect(() => {
    let Fetch = true;

    if (Fetch) {
      fetchTransactions(date);
      Fetch = false;
    }

    return () => {
      Fetch = false;
    };
  }, [date, type]);

  return {
    date,
    type,
    transactions,
    loading,
    FILTER_DATES,
    FILTER_TYPES,
    sendEmail,
    setDate,
    setType,
  };
};

export default useFilterTransactions;